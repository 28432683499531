export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Fecha de pendiente",
          value: "pendingDate",
          sortable: false
        },
        {
          text: "Nro. Pedido",
          sortable: false,
          value: "numOrder"
        },
        {
          text: "Nro. Documento",
          sortable: false,
          value: "numDocument"
        },
        {
          text: "Nombre paciente",
          value: "name",
          sortable: false
        },

        { text: "Días pendientes", value: "daysPending", sortable: false },
        { text: "Prestador", value: "provider", sortable: false }
      ],
      itemsData: [
        {
          pendingDate: "2019-07-08",
          numOrder: 1,
          numDocument: "45354342",
          name: "Pepito Perez",
          daysPending: "4",
          provider: "Helpharma"
        }
      ],
      rowsPerPageItems: [5, 10, 15, 20]
    };
  },
  props: {},
  watch: {},
  mounted() {},
  methods: {
    onRowClick(item) {
      this.$emit("row-click", item);
    }
  }
};
