var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "material-card",
    { staticClass: "mx-4", attrs: { color: "primary" } },
    [
      _c(
        "v-card-title",
        [
          _c("v-text-field", {
            attrs: { label: "Buscar", "hide-details": "" },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _c(
            "v-btn",
            { attrs: { flat: "", icon: "" } },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v("mdi-magnify"),
              ]),
            ],
            1
          ),
          _c("v-spacer"),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          "rows-per-page-text": "Registros por página",
          headers: _vm.headers,
          items: _vm.itemsData,
          "rows-per-page-items": _vm.rowsPerPageItems,
        },
        scopedSlots: _vm._u([
          {
            key: "headerCell",
            fn: function ({ header }) {
              return [
                _c("span", {
                  staticClass: "subheading font-weight-light text-primary",
                  domProps: { textContent: _vm._s(header.text) },
                }),
              ]
            },
          },
          {
            key: "items",
            fn: function (props) {
              return [
                _c(
                  "tr",
                  {
                    staticClass: "row",
                    on: {
                      click: function ($event) {
                        return _vm.onRowClick(props.item)
                      },
                    },
                  },
                  [
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.pendingDate)),
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.numOrder)),
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.numDocument)),
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.name)),
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.daysPending)),
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.provider)),
                    ]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }